import { DecisionTreeNode, NODE_WIDTH, NODE_HEIGHT } from './decision-tree-node';
import { DecisionTreeTable } from './decision-tree-table';
import { DecisionTreeEdge } from './decision-tree-edge';
import { DecisionTreeTableColumn, DecisionTreeTableColumnType } from './decision-tree-table-column';

const HORIZONTAL_SPACING = 160;
const VERTICAL_SPACING = 100;
export const DOCX_STYLE = {
    fontFamily: 'Arial',
    fontSize: '12px',
};

export class DecisionTree {
    root: DecisionTreeNode | null;
    table: DecisionTreeTable;

    constructor(
        csv: string,
        excludedColumns: string[],
        forceBinningColumns: string[],
        columnsRewrittenTypes: Map<string, DecisionTreeTableColumnType>
    ) {
        this.root = null;
        this.table = new DecisionTreeTable(csv, excludedColumns, forceBinningColumns, columnsRewrittenTypes);

        const res = this.table.getColumnWithMinEntropy(true);
        if (!res) return;

        this.root = this.buildTree(
            this.table,
            res.colWithMinEntropy,
            <div className="flex">{ res.colWithMinEntropy.header }</div>,
            true,
            res.minEntropy
        );
        this.root.calculation = this.table.drawProbabilities();
    }

    private getPath(node: DecisionTreeNode, edgeLabel: string, header: string) {
        return (
            (
                <div className="flex items-center flex-wrap">
                    <div className="flex items-center whitespace-nowrap">{ node.path }</div>
                    <div className="mx-1 whitespace-nowrap"> --( <span className="text-sm">{ edgeLabel }</span> )--{ '>' } </div>
                    <div className="flex items-center whitespace-nowrap">{ header }</div>
                </div>
            )
        );
    }

    private buildTree(
        table: DecisionTreeTable,
        colWithMinEntropy: DecisionTreeTableColumn,
        nodePath: JSX.Element,
        isRootNode: boolean,
        minEntropy: number
    ): DecisionTreeNode {

        const node = new DecisionTreeNode(colWithMinEntropy.header, nodePath, isRootNode, minEntropy);
        const edgeLabels = colWithMinEntropy.getUniqueValues();

        for (const edgeLabel of edgeLabels) {
            const clonedTable = table.getFilteredTableByFeatureValue(colWithMinEntropy, edgeLabel);

            const isLabelValuesTheSame = clonedTable.isLabelValuesTheSame();
            const areAllRowsIdentical = clonedTable.areAllRowsIdentical(true);

            if (isLabelValuesTheSame || areAllRowsIdentical) {
                const label = clonedTable.getLabelColumn();
                if(!label) continue;

                // const values = clonedTable.getLabelColumn().getValues();
                const leaveValue = label.getMostFrequentValue(); //values.length > 0 ? values[0] : '';
                const path = this.getPath(node, edgeLabel, leaveValue);

                const edgeTargetNode = new DecisionTreeNode(leaveValue, path, false, 0);
                edgeTargetNode.calculation = (isLabelValuesTheSame ?
                    <div dir="ltr" className="my-4 border border-sky-800 bg-white p-4">
                        All label values are the same.<br/>
                        אנחנו רואים שעבור כל הרשומות הסיווג הוא מושלם.
                    </div>
                    :
                    <div dir="ltr" className="my-4 border border-sky-800 bg-white p-4">
                        <span>
                            All rows are identical.<br/>
                            מקרה מנוון
                            -
                            כל השורות זהות<br/>
                        </span>

                        {
                            isLabelValuesTheSame ? 'All labels the same' : 'לוקחים את הרוב מעמודת החיזוי'
                        }
                    </div>
                );

                node.edges.push(new DecisionTreeEdge(edgeLabel, edgeTargetNode));
            }
            else {
                const res = clonedTable.getColumnWithMinEntropy(true);
                if (res) {
                    const nextCol = res.colWithMinEntropy;
                    const path = this.getPath(node, edgeLabel, nextCol.header);
                    const edgeTargetNode = this.buildTree(clonedTable, nextCol, path, false, res.minEntropy);
                    edgeTargetNode.calculation = clonedTable.drawProbabilities();

                    node.edges.push(new DecisionTreeEdge(edgeLabel, edgeTargetNode));
                }
                else{
                    // DATA issue!!! -----------
                    const values = clonedTable.getLabelColumn().getUniqueValues();

                    let leaveValue = '';
                    if(values.length === 1) {
                        leaveValue = values[0];
                    }
                    else{
                        leaveValue = clonedTable.getLabelColumn().getUniqueValuesWithPercentsString();
                    }

                    const path = this.getPath(node, edgeLabel, leaveValue);
                    const edgeTargetNode = new DecisionTreeNode(leaveValue, path, false, 0);
                    edgeTargetNode.calculation = (
                        <div dir="ltr" className="my-4 border border-red-800 bg-white p-4">
                            Data problem.
                        </div>
                    );
                    node.edges.push(new DecisionTreeEdge(edgeLabel, edgeTargetNode));
                }
            }
        }

        return node;
    }

    public draw(includeCalculations: boolean) {
        if (!this.root) return null;

        const nodes: JSX.Element[] = [];
        const edges: JSX.Element[] = [];
        const calculations: JSX.Element[] = [];

        const queue: [DecisionTreeNode, number][] = [[this.root, 0]];
        const levels: Map<number, DecisionTreeNode[]> = new Map();

        // Group nodes by levels using BFS --------------
        while (queue.length > 0) {
            const [node, level] = queue.shift()!;

            if (!levels.has(level)) {
                levels.set(level, []);
            }
            levels.get(level)!.push(node);

            for (const edge of node.edges) {
                queue.push([edge.edgeTargetNode, level + 1]);
            }
        }

        const allLevels = Array.from(levels.entries()).sort((a, b) => a[0] - b[0]);

        // Determine maximum row width for centering --------------
        let canvasWidth = 0;
        const rowWidths: Map<number, number> = new Map();

        for (const [level, levelNodes] of allLevels) {
            const rowWidth = levelNodes.length * NODE_WIDTH + (levelNodes.length - 1) * HORIZONTAL_SPACING;
            rowWidths.set(level, rowWidth);
            canvasWidth = Math.max(canvasWidth, rowWidth);
        }

        // Layout each row centered within canvasWidth -----------
        for (const [level, levelNodes] of allLevels) {
            const rowWidth = rowWidths.get(level)!;
            let startX = (canvasWidth - rowWidth) / 2;
            const y = level * (NODE_HEIGHT + VERTICAL_SPACING);

            for (const node of levelNodes) {
                node.x = startX;
                node.y = y;
                startX += NODE_WIDTH + HORIZONTAL_SPACING;
            }
        }

        // Collect nodes and edges, and compute canvas size
        let svgWidth = 0;
        let svgHeight = 0;

        for (const [, levelNodes] of allLevels) {
            for (const node of levelNodes) {
                nodes.push(node.draw());

                calculations.push((
                    <div className="my-4 bg-slate-50 px-4 py-2 border border-slate-200" style={ DOCX_STYLE }>
                        <hr />

                        {
                            node.isRootNode &&
                            <>
                                <div className="text-2xl flex mb-2" dir="rtl">חישוב אנטרופיה עבור שורש העץ
                                    ״{ node.text }״:
                                </div>
                                <div dir="rtl">{ node.path }</div>
                            </>
                        }

                        {
                            !node.isRootNode &&
                            <>
                                <div className="text-2xl flex mb-2" dir="rtl">חישוב אנטרופיה עבור צומת
                                    ״{ node.text }״:
                                </div>
                                <div dir="rtl">{ node.path }</div>
                            </>
                        }

                        { node.calculation }
                    </div>
                ));

                svgWidth = Math.max(svgWidth, node.getRightX());
                svgHeight = Math.max(svgHeight, node.getBottomY());

                for (const edge of node.edges) {
                    edges.push(edge.draw(node));
                }
            }
        }

        return (
           <div>
               <div className="max-w-full flex justify-center">
                   <svg
                       id="decision-tree-svg"
                       width={ svgWidth }
                       height={ svgHeight }
                       viewBox={ `0 0 ${ svgWidth } ${ svgHeight }` }
                       xmlns="http://www.w3.org/2000/svg">
                       <rect width="100%" height="100%" fill="#fff"/>
                       { edges }
                       { nodes }
                   </svg>
               </div>

               {
               includeCalculations &&
                   <div className="gap-4 my-6" style={ DOCX_STYLE } dir="rtl">
                       <div>
                           <p>הדגמת שלבי בחירת התכונות המתפצלות בעזרת מדד אנטרופיה:</p>
                           <p>הגעתי לסיווג מושלם בעץ החלטה.</p>
                       </div>

                       { calculations }
                   </div>
               }

           </div>
        );
    }
}