import { ChangeEvent, useState, ClipboardEvent } from 'react';
import { DecisionTreeTable } from './domain/decision-tree-table';
import { DecisionTree } from './domain/decision-tree';
import { PhotoIcon, SaveIcon } from '../../../../../common/ui/icons';
import { download } from '../../../../../common/domain/io-provider';
import { DecisionTreeTableColumnType } from './domain/decision-tree-table-column';

const UI_HEIGHT = '500px';

const DecisionTreeHome = () => {

    const [csv, setCsv] = useState(`
המתנת לקוח  ,זמן המתנה משוער  ,סוג מסעדה  ,הזמנה מראש  ,חמסין  ,רמת מחירים  ,תפוסת לקוחות במסעדה  ,לקוח רעב  ,סוף שבוע  , בר  ,מסעדה חלופית  ,מס' לקוח
כ  ,  0-10, צרפתי  ,כ  ,ל  ,גבוהה  ,בינונית ,כ  ,ל  ,ל  ,כ  ,1
ל  , 30-60,אסייתי  ,ל  ,ל  ,נמוכה  ,  ?,כ  ,ל  ,ל  ,כ  ,2
כ  ,  0-10,מזרחי  ,ל  ,ל  ,נמוכה  ,בינונית ,ל  ,ל  ,כ  ,ל  ,3
כ  ,  ?,אסייתי  ,ל  ,ל  ,נמוכה  ,מלאה  ,כ  ,כ  ,ל  ,כ  ,4
ל  ,  > 60, צרפתי  ,כ  ,ל  ,גבוהה  ,מלאה  ,ל  ,  ?,ל  ,כ  ,5
כ  ,  0-10,איטלקי ,כ  ,כ  ,בינונית ,בינונית ,כ  ,ל  ,כ  ,ל  ,6
ל  ,  0-10,  ?,ל  ,כ  ,נמוכה  ,ריק  ,ל  ,ל  ,כ  ,ל  ,7
כ  ,  0-10,אסייתי  ,כ  ,כ  ,בינונית ,בינונית ,כ  ,ל  ,ל  ,ל  ,8
ל  ,  > 60,מזרחי  ,ל  ,כ  ,נמוכה  ,מלאה  ,ל  ,כ  ,ל  ,ל  ,9
ל  , 10-30,איטלקי ,כ  ,ל  ,גבוהה  ,מלאה  ,כ  ,כ  ,כ  ,כ  ,10
ל  ,  0-10,אסייתי  ,ל  ,ל  ,נמוכה  ,ריק  ,ל  ,ל  ,כ  ,ל  ,11
כ  , 30-60,מזרחי  ,ל  ,ל  ,נמוכה  ,מלאה  ,כ  ,כ  ,כ  ,כ  ,12
    `);
    const [excludedColumns, setExcludedColumns] = useState<string[]>([]);
    const [forceBinningColumns, setForceBinningColumns] = useState<string[]>([]);
    const [columnsRewrittenTypes, setColumnsRewrittenTypes] = useState<Map<string, DecisionTreeTableColumnType>>(new Map());

    const [decisionTreeTable, setDecisionTreeTable] = useState<DecisionTreeTable|null>(
        (csv.trim() === '' ?
            null :
            new DecisionTreeTable(csv, excludedColumns, forceBinningColumns, columnsRewrittenTypes)
        ) || null
    );

    const [decisionTree, setDecisionTree] = useState<DecisionTree|null>(
        csv.trim() === '' ?
            null :
            new DecisionTree(csv, excludedColumns, forceBinningColumns, columnsRewrittenTypes)
    );

    const [includeCalculations, setIncludeCalculations] = useState(false);

    const onClick = () => {

        if(csv.trim() === '') {
            setDecisionTreeTable(null);
            setDecisionTree(null);
        }
        else{
            setDecisionTreeTable(new DecisionTreeTable(csv, excludedColumns, forceBinningColumns, columnsRewrittenTypes));
            setDecisionTree(new DecisionTree(csv, excludedColumns, forceBinningColumns, columnsRewrittenTypes));
        }

        setCsv(csv.trim().replaceAll('', 'נ'));
    };

    const onTextAreaChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
        setCsv(evt.target.value);
    };

    const onTextAreaPaste = (evt: ClipboardEvent<HTMLTextAreaElement>) => {
        evt.preventDefault();
        const pastedText = evt.clipboardData.getData('text');
        const csv = pastedText.trim().replaceAll(/\t+/g,',').trim();
        
        if(csv) {
            setDecisionTreeTable(null);
            setDecisionTree(null);
        }
        else{
            setDecisionTreeTable(new DecisionTreeTable(csv, excludedColumns, forceBinningColumns, columnsRewrittenTypes));
            setDecisionTree(new DecisionTree(csv, excludedColumns, forceBinningColumns, columnsRewrittenTypes));
        }

        setCsv(csv);
    };

    const onIncludeCalculationsChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setIncludeCalculations(evt.target.checked);
    };

    const onSaveAsPngClick = async () => {
        const $svg = document.getElementById('decision-tree-svg') as unknown as SVGSVGElement;
        if(!$svg) return;

        await download({
            $svg,
            outfileName: 'decision-tree',
            ext: 'png',
        });
    };

    const onFullScreenClick = async () => {
        const $svg = document.getElementById('decision-tree-svg') as unknown as SVGSVGElement;
        if(!$svg) return;

        await $svg.requestFullscreen();
    };

    const onExcludeChange = (header: string, isChecked: boolean) => {

        const set = new Set(excludedColumns);

        if(set.has(header)) {
            if(!isChecked) {
                set.delete(header);
            }
        }
        else{
            if(isChecked) {
                set.add(header);
            }
        }

        const _excluded = [...set];
        setExcludedColumns(_excluded);
        setDecisionTreeTable(csv.trim() === '' ? null : new DecisionTreeTable(csv, _excluded, forceBinningColumns, columnsRewrittenTypes));
        setDecisionTree(csv.trim() === '' ? null : new DecisionTree(csv, _excluded, forceBinningColumns, columnsRewrittenTypes));
    };

    const onForceBinningChange = (header: string, isChecked: boolean) => {
        const set = new Set(forceBinningColumns);

        if(isChecked) {
            set.delete(header);
        }
        else{
            set.add(header);
        }

        const _forceBinningColumns = [...set];
        setForceBinningColumns(_forceBinningColumns);
        setDecisionTreeTable(csv.trim() === '' ? null : new DecisionTreeTable(csv, excludedColumns, _forceBinningColumns, columnsRewrittenTypes));
        setDecisionTree(csv.trim() === '' ? null : new DecisionTree(csv, excludedColumns, _forceBinningColumns, columnsRewrittenTypes));
    };

    const onTypeChange = (header: string, newType: DecisionTreeTableColumnType) => {
        const copy = new Map(columnsRewrittenTypes);
        copy.set(header, newType);
        setColumnsRewrittenTypes(copy);
        setDecisionTreeTable(csv.trim() === '' ? null : new DecisionTreeTable(csv, excludedColumns, forceBinningColumns, copy));
        setDecisionTree(csv.trim() === '' ? null : new DecisionTree(csv, excludedColumns, forceBinningColumns, copy));
    };

    return(
        <div className="w-full py-2 overflow-auto" dir="rtl" style={ {
            maxWidth: 'calc(100vw - 200px)',
        } }>

            <div className="flex items-center w-full mb-4" dir="ltr">
                <h1 className="text-2xl px-4">Decision Tree</h1>

                <button
                    type="button"
                    onClick={ onClick }
                    className="px-6 bg-sky-100 rounded mx-6 border border-sky-300">
                    Update
                </button>

                <label className="text-sm">
                    <input
                        type="checkbox"
                        onInput={ onIncludeCalculationsChange }
                        className="mx-2"
                    />
                    <span>Include Calculations</span>
                </label>

            </div>

            <div className="flex gap-6">
                <div className="flex flex-col">
                    <textarea
                        id="data"
                        onChange={ onTextAreaChange }
                        onPaste={ onTextAreaPaste }
                        className="border rounded w-full p-2 border border-slate-400 shadow-md text-sm"
                        style={ {
                            width: '300px',
                            height: UI_HEIGHT,
                        } }
                        value={ csv }
                    />

                </div>

                { decisionTreeTable?.draw(onExcludeChange, onForceBinningChange, onTypeChange) }
            </div>

            <div className="my-4 text-sm max-w-full mr-auto overflow-auto" dir="ltr">
                {/*<EntropyFormula/>*/ }

                <div className="flex items-center">
                    <button
                        onClick={ onSaveAsPngClick }
                        className="flex items-center mr-4"
                        type="button">
                        <SaveIcon color={ '#000' } size={ 20 } classes="mr-2"/>
                        Save as PNG
                    </button>

                    <button
                        onClick={ onFullScreenClick }
                        className="flex items-center"
                        type="button">
                        <PhotoIcon color={ '#000' } size={ 17 } classes="mr-2"/>
                        FullScreen
                    </button>
                </div>

                <div>{ decisionTree?.draw(includeCalculations) }</div>
            </div>


            <p className="text-xs text-sky-700 mx-4 my-20 bg-slate-100 p-4 border rounded border-slate-200" dir="ltr">
                Нужно открыть PDF в WORD, а затем скопировать таблицу в EXCEL. Его можно сохранить как CSV, который
                можно скопировать сюда.<br/><br/>

                Не нужно строить всю таблицу: нужно шореш и еще пару рамот. А ниже просто ставим троеточия.<br/><br/>

                label - (עמודה לסיווג) עמודה לחיזוי.<br/><br/>
                אפשר להסיר עמודות (תכונות) חד-חד-ערכיות כי אנחנו לא משתמשים בהם במהלך הסיווג.<br/><br/>
                עמודות מראה = עמודות עם ערכים הפוכים.<br/><br/>
                הגעתי לסיווג מושלם<br/><br/>
                קיבלנו סיווג מושלם<br/><br/>
                מכיוון ויתא לי עץ עם סיווג מושלם בעל 2 רמות וביקשו 3 רמות אזי אני מוסיפה רמה אחת נוספת
            </p>
        </div>
    )
};

export default DecisionTreeHome;
